import { render, staticRenderFns } from "./addcollect.vue?vue&type=template&id=66500b55&scoped=true&"
import script from "./addcollect.vue?vue&type=script&lang=js&"
export * from "./addcollect.vue?vue&type=script&lang=js&"
import style0 from "./addcollect.vue?vue&type=style&index=0&id=66500b55&prod&scoped=true&lang=css&"
import style1 from "./addcollect.vue?vue&type=style&index=1&id=66500b55&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66500b55",
  null
  
)

export default component.exports