let videoMain = function(tokenClient, serviceAddr) {
	let TokenClient = tokenClient
	let ServiceBaseAddress = serviceAddr

	/**
	 * 获取收藏列表数据
	 * @param {any} success
	 * @param {any} error
	 */
	 this.getCategories = function(success, error) {
		var url = ServiceBaseAddress + '/api/Video/Categories'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.Collections = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Collection/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetMyselfVideo = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/GetMyselfVideo/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.MyselfCollection = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/GetMyselfCollection/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getIndexVideoList = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/IndexVideoList/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetAllVideoList = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/GetAllVideo/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetVideoAllOrder = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/GetVideoAllOrder/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	
	this.DeleteCollection = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/DeleteCollection/' + params
		TokenClient.Delete(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getDeleteVideo = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/DeleteVideo/' + params
		TokenClient.Delete(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetCollectionVideo = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/GetCollectionVideo/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetVideoAllComment = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/GetVideoAllComment/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetHotList = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/GetHotList/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.VideoDetail = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/Detail/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetVideoCollectionDetail = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/GetVideoCollectionDetail/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getIsPayVideo = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Video/IsPayVideo/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.isUserDistributor = function(success, error) {
		var url = ServiceBaseAddress + '/api/User/UserDistributor'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	
	this.getVideoPayNative = function(param, success, error) {
		var url = ServiceBaseAddress + '/api/Video/VideoPayNative'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getPayByBalance = function(param, success, error) {
		var url = ServiceBaseAddress + '/api/Video/PayByBalance'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getVideoIsPaySuccess = function(param, success, error) {
		var url = ServiceBaseAddress + '/api/Video/VideoIsPaySuccess'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.AddComment = function(param, success, error) {
		var url = ServiceBaseAddress + '/api/Video/AddComment'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.EditVideo = function(param, success, error) {
		var url = ServiceBaseAddress + '/api/Video/EditVideo'
		TokenClient.Put(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	 /**
     * 获取阿里云视频ID
     * @param success
     * @param error
     * @constructor
     */
	  this.GetVideoInfo = function (videoId,success, error) {
        var url = ServiceBaseAddress + '/api/Video/VideoInfo/'+videoId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	this.AddVideo = function(param, success, error) {
		var url = ServiceBaseAddress + '/api/Video/AddVideo'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.VideoCertificate = function(param, success, error) {
		var url = ServiceBaseAddress + '/api/Video/GetVideoCertificate'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.operateVideoCollection = function(param, success, error) {
		var url = ServiceBaseAddress + '/api/Video/operateVideoCollection'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	this.getPutCollection = function(param, success, error) {
		var url = ServiceBaseAddress + '/api/Video/PutCollection'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getEditCollection = function(param, success, error) {
		var url = ServiceBaseAddress + '/api/Video/EditCollection'
		TokenClient.Put(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	/**
	 * 收藏
	 * @param {any} success
	 * @param {any} error
	 */
	this.Put = function(
		type,
		entityName,
		entityKey,
		entityId,
		entityType,
		success,
		error
	) {
		var url = ServiceBaseAddress + '/api/Collection/Put'
		var param = {
			type: type,
			entityName: entityName,
			entityKey: entityKey,
			entityId: entityId,
			entityType: entityType,
		}
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 删除
	 * @param {any} success
	 * @param {any} error
	 */
	this.Delete = function(referralId, success, error) {
		var url = ServiceBaseAddress + '/api/Collection/RemoveAll/' + referralId
		TokenClient.Delete(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
}

export { videoMain }
